import { apiCall, stringify } from './utils';

export const pages = {

  get: async (id) => {

    return await apiCall(`/pages/${id}`, { method: 'GET', params: {}});

  },

  list: async (parameters = {}) => {

    const params = stringify(parameters);
    return await apiCall('/pages', { method: 'GET', params } );

  },

  getByURL: async (url) => {

    return await apiCall(`/pages/url/${url}`, { method: 'GET', params: { url } });

  },

  sections: {

    list: async (parameters = {}) => {

      const params = stringify(parameters);
      return await apiCall('/pages/sections', { method: 'GET', params });

    }
  }
}
