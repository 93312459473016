

export const CLIENT = process.client;
export const SERVER = process.server;

export const DEV = process.env.NODE_ENV === 'development';
export const PROD = process.env.NODE_ENV !== 'development';

export const BREAKPOINTS = {
  XS: 320,
  S: 640,
  M: 768,
  L: 1024,
  XL: 1280,
  XXL: 1440,
}

export const DIMENSIONS = {
  mobile: '>640',
  normal: '<768',
  billboard: '<1280',
}
