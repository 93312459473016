import { apiCall, findItem, randomPassword } from "./utils"
import { storage } from "./Storage"

// const SITE_ID = 2
// const CATALOG_ID = 5
// const STORE_ID = 6
// const PRICE_GROUP_ID = 2
export const customers = {

  create: async (customer) => {

    const body = { ...customer }
    return await apiCall(`/customers`, { method: 'POST', body });

  },

  get: async (id) => {

    return await apiCall(`/customers/${id}`, { method: 'GET', params: {}});

  },

  list: async (parameters = {}) => {

    return await apiCall(`/customers`, { method: 'GET', params: parameters });

  },

  // findByEmail: async (email) => {
  //   const { data } = await customers.list({ email });
  //   return data?.value?.partners[0];
  // },

  addresses: {

    create: async (address) => {

      return  await apiCall(`/customers/addresses`, { method: 'POST', body: address });

    },

    // list: async (parameters = {}) => {
    //
    //   return await apiCall(`/customers/addresses`, { method: 'GET', params: parameters });
    //
    // },

    // delete: async (id) => {
    //   const { data } =  await apiCall(`/customers/addresses/${id}`, { method: 'DELETE', body: id });
    //   return data;
    // }

  }

}
