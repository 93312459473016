import { apiCall, findItem, stringify } from './utils'
import { storage } from "./Storage"

const SITE_ID = 2
const CATALOG_ID = 5

export const categories = {

  list: async (parameters = {}) => {

    const params = stringify(parameters);
    return await apiCall('/categories', { method: 'GET', params } );

  },

  getByURL: async (url) => {
    const response = await storage.getItem("categories")
    let parent = findItem(response, url[0], "url")
    for (let i = 1; i < url.length; i++) {
      const child = findItem(parent.children, url[i], "url")
      if (child) parent = child
    }
    return parent
  },
}
