import { apiCall, randomPassword } from './utils'

const STORE_ID = 6

export const orders = {

  list: async (parameters = {}) => {

    return await apiCall(`/orders`, { method: 'GET', params: parameters });

  },

  create: async (user, items) => {

    const body = {
      partner_id: user.partner_id,
      partner_name: user.name,
      address: user.address,

      items: items,
      comment: 'Order from the store front!'
    };

  //  return 'Something';
    return  await apiCall(`/orders`, { method: 'POST', body });
  },


  // partner: {},
  // shippingAddress: {},
  // billingAddress: null,


    // insert: async ({partnerData, paymentMethodId, deliveryMethodId, comment = "", coupon, items, shippingAddress, billingAddress}) => {
    //     let email = partnerData.email;
    //
    //     this.setPartner(partnerData);
    //
    //     this.setAddresses(shippingAddress, billingAddress);
    //
    //     const body = {
    //         partner_id: this.partner.id,
    //         payment_method_id: paymentMethodId, // selected payment method id
    //         delivery_method_id: deliveryMethodId, // selected delivery method id
    //         partner_name: this.partner.name,
    //         email: email,
    //         status_id: 1, // default status id,
    //         payment_status_id: this.setPaymentStatusId(paymentMethodId), // default payment status id
    //         shipment_status_id: 1, // default shipment status id
    //         comment,
    //
    //         // all items in the cart in an array of objects like bellow
    //         // {
    //         //     product_id: 1, (id of the product)
    //         //     qty: 1, (quantity of current product in the cart)
    //         //     items: [ (array of items related to the product)
    //         //         product_id: 1, (id of the related product)
    //         //         qty: 1, (quantity of current related product in the cart)
    //         //         group_id: 1, (group of the relation for example Házbaszállítás at netenbutor.hu)
    //         //     ],
    //         // }
    //         items,
    //         address: this.shippingAddress,
    //         billing_address: this.billingAddress,
    //     };
    //
    //     if (coupon && coupon.id) {
    //         body.coupon_id = coupon.id;
    //         body.coupon_code = coupon.code;
    //     }
    //
    //     return await apiCall(`/order/orders/public2`, {body, method: 'POST'});
    //
    // },
    //
    // setPartner: async (partnerData) => {
    //     // TODO: Implement this function
    //     let email = partnerData.email;
    //     // If authenticated, get partner id from user
    //     if (false) //user is authenticated
    //     {
    //        // TODO get partner id from auth user
    //     }
    //     else {
    //         // else search for partner by email (keep in mind that for every store the partner id is unique)
    //         this.partner = await this.findPartnerByEmail(email);
    //
    //         // if partner not found, create a new partner
    //         if (!this.partner) {
    //             this.partner = await this.createPartner(partnerData);
    //         }
    //     }
    // },
    //
    // createPartner: async (partnerData) => {
    //     let body = {
    //         store_id: 1, // needs to be changed to the current store id
    //         partner_type_id: [1], // 1 for individual partner
    //         name: partnerData.name,
    //         email: partnerData.email,
    //         tax_number: partnerData.tax_number,
    //         is_company: partnerData.is_company, // 0 for individual and 1 for company
    //         status_id: partnerData.is_guest ? 2 : 1, // 2 for guest and 1 for registered
    //         firstname: partnerData.firstname,
    //         lastname: partnerData.lastname,
    //         username: partnerData.email,
    //         password: partnerData.password,
    //     };
    //
    //     let { data } = await apiCall(`/user/partners/register`, {body, method: 'POST'});
    //
    //     if (data?.value?.partner) {
    //         return data.value.partner;
    //     }
    //
    //     return null;
    // },
    //
    // findPartnerByEmail: async (email) => {
    //     let filters = {
    //         email: [email],
    //         store_id: [1] // needs to be changed to the current store id
    //     };
    //
    //     let { data } = await apiCall(`/user/partners`, {params: {filters}});
    //
    //     if (data?.value?.partners && data.value.partners.length > 0) {
    //         return data.value.partners[0];
    //     }
    //
    //     return null;
    // },
    //
    // setPaymentStatusId: (paymentMethodId) => {
    //     // decide the default payment status id based on the payment method id
    //     if (paymentMethodId === 4) {
    //         return 11;
    //     } else if (paymentMethodId === 3) {
    //         return 10;
    //     } else {
    //         return 13;
    //     }
    // },
    //
    // setAddresses: (shippingAddress, billingAddress) => {
    //     this.shippingAddress = {
    //         partner_id: this.partner.id,
    //         address_type_id: 3, // 3 for shipping address
    //         country_id: 1, // default country id (Hungary)
    //         name: shippingAddress.name, // name of the partner
    //         phone: shippingAddress.phone, // phone number of the partner
    //         zip: shippingAddress.zip, // zip code of the address
    //         city: shippingAddress.city, // city of the address
    //         street: shippingAddress.publicplace_name, // street of the address same as public place name since this is just the free text field
    //         publicplace_name: shippingAddress.publicplace_name, // name of the public place
    //         is_default: 0, // 0 for not default
    //     }
    //
    //     if (billingAddress.sameData && billingAddress.tax_number) {
    //         this.shippingAddress.tax_number = billingAddress.tax_number;
    //     }
    //     else if (!billingAddress.sameData && (billingAddress.id !== shippingAddress.id || !shippingAddress.id)) {
    //         this.billingAddress = {
    //             partner_id: this.partner.id,
    //             address_type_id: 4, // 4 for billing address
    //             country_id: 1, // default country id (Hungary)
    //             name: billingAddress.name, // name of the partner
    //             phone: billingAddress.phone, // phone number of the partner
    //             zip: billingAddress.zip, // zip code of the address
    //             city: billingAddress.city, // city of the address
    //             street: billingAddress.public_place_name, // street of the address same as public place name since this is just the free text field
    //             public_place_name: billingAddress.public_place_name, // name of the public place
    //             tax_number: billingAddress.tax_number, // tax number of the partner
    //             is_default: 0, // 0 for not default
    //         }
    //     }
    //
    //     this.shippingAddress.partner_address_id = shippingAddress.id ? shippingAddress.id : this.createPartnerAddress(this.shippingAddress);
    //
    //     if (this.billingAddress) {
    //         this.billingAddress.partner_address_id = billingAddress.id ? billingAddress.id : this.createPartnerAddress(this.billingAddress);
    //     }
    // },
    //
    // createPartnerAddress: async (address) => {
    //     let { data } =  await apiCall(`/user/partnerAddresses`, {body: address, method: 'POST'});
    //
    //     return data.value.id;
    // }
}
