import { apiCall, stringify } from './utils';

export const products = {

  get: async (id) => {

    return await apiCall(`/products/${id}`, { method: 'GET', params: {}});

  },

  list: async (parameters = {}) => {

    const params = stringify(parameters);
    return await apiCall('/products', { method: 'GET', params } );

  },

  getByURL: async (url) => {

    return await apiCall(`/products/url/${url}`, { method: 'GET' });

  },
}
