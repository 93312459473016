<script setup>

const error = useError();

</script>

<template>
  <div class="error">
    <div class="wrapper">
      <h1> {{ error.statusCode }}</h1>
      <p> {{ error.statusMessage }}</p>
    </div>
  </div>
  
</template>

<style scoped lang="scss">
.error {
  display: grid;
  width: 100vw;
  height: 100vh;
  place-items: center;
  
  .wrapper {
    h1 {
      font-size: 15rem;
      color: var(--color1);
    }
    
    p {
      margin-top: 5rem;
      font-size: 3rem;
      color: var(--color1);
    }
  }
}
</style>
