import { ref } from 'vue'
import { auth } from './Auth';
import { storage } from './Storage';
import { categories } from './Categories';
import { customers } from './Customers';
import { products } from "./Products";
import { menus } from "./Menus";
import { pages } from "./Pages";
import { orders } from "./Orders";
import { DIMENSIONS } from "~/utils/Config";

const VERSION = '0.0.1';

const _breakpoint = ref();

export default class Dairamon {
  constructor() {

    // PUBLIC VARS
    this.version = VERSION;

    // CONTENT
    this.auth = auth;
    this.pages = pages;
    this.menus = menus;
    this.customers = customers;
    this.categories = categories;
    this.products = products;
    this.orders = orders;

    // STORAGE
   this.storage = storage;

  }

  welcome() {

    if (PROD && CLIENT) console.log(`%cMade By Ginrin`, 'color: #36210a; background: #faa61a; padding:2px 6px;');

  }

  onMounted() {

    this.style = getComputedStyle(document.body);
    this.addEventListeners();

  }

  addEventListeners() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize = () => {

    const size = window.innerWidth;
    for (const [key, value] of Object.entries(BREAKPOINTS)) {
      if (size < value) break;
      _breakpoint.value = key;
    }

    document.documentElement.setAttribute('style', `--inner-height: ${window.innerHeight}px`)

  }

  getBreakpoint() {
    return _breakpoint.value;
  }

  getImageDimension(object) {
    const size = BREAKPOINTS[_breakpoint.value];
    let dimension = 'normal';
    for (const [key] of Object.entries(object)) {
      if (DIMENSIONS.hasOwnProperty(key)) {
        const operator = DIMENSIONS[key].includes('>') ? '>' : DIMENSIONS[key].includes('<') ? '<' : null;
        const value = operator ? DIMENSIONS[key].slice(1) : DIMENSIONS[key];
        if (operator === '>' && value >= size) dimension = key;
        if (operator === '<' && value <= size) dimension = key;
      }
    }
    return dimension;
  }

  userId() {
    return window.sessionStorage.getItem("user_id")
  }
}
