const _storage = {};
let _proxy;
let _length = 0;

class Storage {
  constructor() {
    this.init();
  }

  init() {
    _proxy = new Proxy(_storage, {
      set: (object, key, value) => {
        object[key] = value;
        if (this[key] === undefined) _length++;
        this[key] = object[key];
        this.length = _length;
        return true;
      }
    });
  }

  setItem(key, value) {
    _proxy[key] = value;
  }

  async getItem(key) {
    await this.run(key);
    return _proxy[key];
  }

  removeItem(key) {
    if (_proxy[key]) {
      delete _proxy[key];
      delete this[key];
      _length--;
      this.length = _length;
    }
  }

  run(key) {
    return new Promise((resolve, reject) => {
      promiseLoop()
      function promiseLoop() {
        const timeoutID = setTimeout(promiseLoop, 100, key, _proxy)
        if (_proxy[key]) {
          clearTimeout(timeoutID)
          resolve()
        }
      }
    })
  }
}

const storage = new Storage();
export { storage };
