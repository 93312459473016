import { default as adatvedelemuOS4wsGQFnMeta } from "/opt/atlassian/pipelines/agent/build/pages/adatvedelem.vue?macro=true";
import { default as _91url_93zP7f0n97JwMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/[url].vue?macro=true";
import { default as indexLAlQRJLYeOMeta } from "/opt/atlassian/pipelines/agent/build/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46url_936ByoBd2kWNMeta } from "/opt/atlassian/pipelines/agent/build/pages/categories/[...url].vue?macro=true";
import { default as dropshippingLtcD5hwQvTMeta } from "/opt/atlassian/pipelines/agent/build/pages/dropshipping.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as kapcsolatMtJaqSsEebMeta } from "/opt/atlassian/pipelines/agent/build/pages/kapcsolat.vue?macro=true";
import { default as _91url_93AkE0LvFHfyMeta } from "/opt/atlassian/pipelines/agent/build/pages/products/[url].vue?macro=true";
import { default as rolunk9xFYFcasKPMeta } from "/opt/atlassian/pipelines/agent/build/pages/rolunk.vue?macro=true";
import { default as szolgaltatasaink5Dwgthd49aMeta } from "/opt/atlassian/pipelines/agent/build/pages/szolgaltatasaink.vue?macro=true";
import { default as test1g9xwIZN1aMeta } from "/opt/atlassian/pipelines/agent/build/pages/test.vue?macro=true";
import { default as vasarlasi_45feltetelekSCer6434svMeta } from "/opt/atlassian/pipelines/agent/build/pages/vasarlasi-feltetelek.vue?macro=true";
import { default as webaruhazc8iA2Gp2SbMeta } from "/opt/atlassian/pipelines/agent/build/pages/webaruhaz.vue?macro=true";
export default [
  {
    name: adatvedelemuOS4wsGQFnMeta?.name ?? "adatvedelem___hu",
    path: adatvedelemuOS4wsGQFnMeta?.path ?? "/adatvedelem",
    meta: adatvedelemuOS4wsGQFnMeta || {},
    alias: adatvedelemuOS4wsGQFnMeta?.alias || [],
    redirect: adatvedelemuOS4wsGQFnMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/adatvedelem.vue").then(m => m.default || m)
  },
  {
    name: _91url_93zP7f0n97JwMeta?.name ?? "blog-url___hu",
    path: _91url_93zP7f0n97JwMeta?.path ?? "/blog/:url()",
    meta: _91url_93zP7f0n97JwMeta || {},
    alias: _91url_93zP7f0n97JwMeta?.alias || [],
    redirect: _91url_93zP7f0n97JwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/[url].vue").then(m => m.default || m)
  },
  {
    name: indexLAlQRJLYeOMeta?.name ?? "blog___hu",
    path: indexLAlQRJLYeOMeta?.path ?? "/blog",
    meta: indexLAlQRJLYeOMeta || {},
    alias: indexLAlQRJLYeOMeta?.alias || [],
    redirect: indexLAlQRJLYeOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46url_936ByoBd2kWNMeta?.name ?? "categories-url___hu",
    path: _91_46_46_46url_936ByoBd2kWNMeta?.path ?? "/categories/:url(.*)*",
    meta: _91_46_46_46url_936ByoBd2kWNMeta || {},
    alias: _91_46_46_46url_936ByoBd2kWNMeta?.alias || [],
    redirect: _91_46_46_46url_936ByoBd2kWNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/categories/[...url].vue").then(m => m.default || m)
  },
  {
    name: dropshippingLtcD5hwQvTMeta?.name ?? "dropshipping___hu",
    path: dropshippingLtcD5hwQvTMeta?.path ?? "/dropshipping",
    meta: dropshippingLtcD5hwQvTMeta || {},
    alias: dropshippingLtcD5hwQvTMeta?.alias || [],
    redirect: dropshippingLtcD5hwQvTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/dropshipping.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___hu",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kapcsolatMtJaqSsEebMeta?.name ?? "kapcsolat___hu",
    path: kapcsolatMtJaqSsEebMeta?.path ?? "/kapcsolat",
    meta: kapcsolatMtJaqSsEebMeta || {},
    alias: kapcsolatMtJaqSsEebMeta?.alias || [],
    redirect: kapcsolatMtJaqSsEebMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/kapcsolat.vue").then(m => m.default || m)
  },
  {
    name: _91url_93AkE0LvFHfyMeta?.name ?? "products-url___hu",
    path: _91url_93AkE0LvFHfyMeta?.path ?? "/products/:url()",
    meta: _91url_93AkE0LvFHfyMeta || {},
    alias: _91url_93AkE0LvFHfyMeta?.alias || [],
    redirect: _91url_93AkE0LvFHfyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/products/[url].vue").then(m => m.default || m)
  },
  {
    name: rolunk9xFYFcasKPMeta?.name ?? "rolunk___hu",
    path: rolunk9xFYFcasKPMeta?.path ?? "/rolunk",
    meta: rolunk9xFYFcasKPMeta || {},
    alias: rolunk9xFYFcasKPMeta?.alias || [],
    redirect: rolunk9xFYFcasKPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rolunk.vue").then(m => m.default || m)
  },
  {
    name: szolgaltatasaink5Dwgthd49aMeta?.name ?? "szolgaltatasaink___hu",
    path: szolgaltatasaink5Dwgthd49aMeta?.path ?? "/szolgaltatasaink",
    meta: szolgaltatasaink5Dwgthd49aMeta || {},
    alias: szolgaltatasaink5Dwgthd49aMeta?.alias || [],
    redirect: szolgaltatasaink5Dwgthd49aMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/szolgaltatasaink.vue").then(m => m.default || m)
  },
  {
    name: test1g9xwIZN1aMeta?.name ?? "test___hu",
    path: test1g9xwIZN1aMeta?.path ?? "/test",
    meta: test1g9xwIZN1aMeta || {},
    alias: test1g9xwIZN1aMeta?.alias || [],
    redirect: test1g9xwIZN1aMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/test.vue").then(m => m.default || m)
  },
  {
    name: vasarlasi_45feltetelekSCer6434svMeta?.name ?? "vasarlasi-feltetelek___hu",
    path: vasarlasi_45feltetelekSCer6434svMeta?.path ?? "/vasarlasi-feltetelek",
    meta: vasarlasi_45feltetelekSCer6434svMeta || {},
    alias: vasarlasi_45feltetelekSCer6434svMeta?.alias || [],
    redirect: vasarlasi_45feltetelekSCer6434svMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/vasarlasi-feltetelek.vue").then(m => m.default || m)
  },
  {
    name: webaruhazc8iA2Gp2SbMeta?.name ?? "webaruhaz___hu",
    path: webaruhazc8iA2Gp2SbMeta?.path ?? "/webaruhaz",
    meta: webaruhazc8iA2Gp2SbMeta || {},
    alias: webaruhazc8iA2Gp2SbMeta?.alias || [],
    redirect: webaruhazc8iA2Gp2SbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/webaruhaz.vue").then(m => m.default || m)
  }
]