import { apiCall, stringify } from './utils';

export const menus = {

  get: async (id, filters = {}) => {

    return await apiCall(`/menus/${id}`, { method: 'GET', params: {}});

  },

  list: async (parameters = {}) => {

    const params = stringify(parameters);
    return await apiCall('/menus', { method: 'GET', params } );

  }
}
