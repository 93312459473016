import { apiCall } from './utils';

export const auth = {

    signIn: async ({ username, password, redirect = false}) => {
        const { signIn, data } = useAuth();
        await signIn('credentials', { username, password, redirect})
        return data.value.partner ? data.value.partner : null;
    },

    signUp: async ({firstname,lastname,email,password}) => {

    },

    signOut: async ({ redirect = false }) => {
        const { signOut } = useAuth();

        await signOut({ redirect });
        return true;
    },

    getSession: async () => {
        const { getSession } = useAuth();
        return await getSession();
    },
}
