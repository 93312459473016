const apiCall = async (url, options = {}) => {
  const _url = "/api" + url
  return useFetch(_url, options)
}

const findItem = (array, value, key) => {
  return array.find((item) => item[key] === value)
}

const randomPassword = () => {
  const length = 16;
  let characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = characters.charAt(Math.floor(Math.random() * characters.length + 1));
  characters += "-_0";
  let n = characters.length;
  for (let i = 0; i < length - 1; i++) {
    password += characters.charAt(Math.floor(Math.random() * n));
  }
  return password;
}

const stringify = (object) => {
  for (const property in object) {
    if (Array.isArray(object[property])) {
      object[property] = JSON.stringify(object[property]);
    }
  }
  return object;
}

export { apiCall, findItem, randomPassword, stringify }
