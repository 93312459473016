import Dairamon from './Dairamon'

export default defineNuxtPlugin(async (nuxtApp) => {
  const dairamon = new Dairamon();
  dairamon.welcome();

  nuxtApp.hook('app:mounted', () => {
    dairamon.onMounted();
  })

  return {
    provide: { dairamon }
  }
})
